import PropTypes from "prop-types";
import React from "react";

import Clients from "../components/clients/Clients";
import i18nContext from "../components/i18n/i18n.context";
import Layout from "../components/layout/Layout";
import Page from "../components/page/Page";
import SEO from "../components/seo/Seo";

function ClientsPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "fr" }}>
      <Layout>
        <SEO
          keywords={["Jawg", "JawgMaps", "Clients", "Témoignages"]}
          location={location}
          metaDescription="Découvrez les success stories de nos clients qui utilisent nos services de cartes."
          title="Clients"
        />
        <Page blue>
          <Clients />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

ClientsPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default ClientsPage;
